import type { FC } from "react";
import React from "react";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import HealthSyncSetup from "../../HealthSync/HealthSyncSetup";
import translate from "src/lib/translate";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";

const HealthSyncSetupDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
    document.dispatchEvent(new CustomEvent("popupClosed", { bubbles: true }));
  };

  return (
    <InDialog
      popup
      title={translate("dataSync.setup.title")}
      onClose={onClose}
      returnUrl={returnUrl}
      simple
    >
      <HealthSyncSetup onCancel={onClose} onComplete={onClose} />
    </InDialog>
  );
};

export default HealthSyncSetupDialog;
